import { InfoPasarPaginaComponent } from './info-pasar-pagina/info-pasar-pagina.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ProductQtyComponent } from './product-qty/product-qty.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PreventDoubleClickDirective } from './prevent-double-click.directive';
import { OnlyNumberDirective } from './only-number.directive';
import { BrowserShareComponent } from './browser-share/browser-share.component';
import { SnowComponent } from './snow.component';
import { LoginCardComponent } from './login-card/login-card.component';
import { MessageComponent } from './message/message.component';
import { AcceptanceModalComponent } from './acceptance-modal/acceptance-modal.component';
import { ConfirmPopoverComponent } from './confirm-popover/confirm-popover.component';
import { ShopCardComponent } from './shop-card/shop-card.component';
import { RouterModule } from '@angular/router';
import { ValidateDNI } from './validate-dni.directive';
import { UploadFilePopoverComponent } from './upload-file-popover/upload-file-popover.component';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { CircularProgressBarComponent } from "./circular-progress-bar/circular-progress-bar.component";
import { SearchBarComponent } from "./search-bar/search-bar.component";
import { MobileToolbarComponent } from "./mobile-toolbar/mobile-toolbar.component";
import {
  AmbassadorProductsTableComponent
} from "../embajadores/ambassador-products-table/ambassador-products-table.component";
import { PipesModule } from "../pipes/pipes.module";
import { CloseModalButtonComponent } from "./close-modal-button/close-modal-button.component";
import { BlankSheetsModalComponent } from "./blank-sheets-modal/blank-sheets-modal.component";
import { PrintPermissionPolicyComponent } from "./print-permission-policy/print-permission-policy.component";
import { PreventClickOnConditionDirective } from './prevent-click-on-condition.directive';
import { PrintSettingsLabelsComponent } from "./print-settings-labels/print-settings-labels.component";
import { TooltipModule } from "@cloudfactorydk/ng2-tooltip-directive";
import { ConfirmModalComponent } from "./confirm-modal/confirm-modal.component";
import { ChangeTextFieldModalComponent } from "./change-text-field-modal/change-text-field-modal.component";
import {
  GroupedBindingIndividualSettingsComponent
} from "./grouped-binding-individual-settings/grouped-binding-individual-settings.component";
import { QtyStepperModule } from "../shared/shop/qty-stepper/qty-stepper.module";
import { OptionsButtonModule } from "../home/options-button/options-button.module";
import {
  IndividualBindingOptionsModalComponent
} from "./individual-binding-options-modal/individual-binding-options-modal.component";
import { BindingOptionsModule } from "../home/binding-options/binding-options.module";
import {
  InfoProgramacionDidacticaModalComponent
} from "./info-programacion-didactica-modal/info-programacion-didactica-modal.component";
import { ConfirmModalCartComponent } from './confirm-modal-cart/confirm-modal-cart.component';
import { CoverSettingsComponent } from "./cover-settings/cover-settings.component";
import {
  TooltipInfoDespaginationComponent
} from "./cover-settings/tooltip-info-despagination/tooltip-info-despagination.component";
import { VideoPlayerModalComponent } from "./video-player-modal/video-player-modal.component";
import { ConfirmCommentModalComponent } from "./confirm-comment-modal/confirm-comment-modal.component";
import { OrdersBlockedAlertComponent } from "./orders-blocked-alert/orders-blocked-alert.component";
import { GroupedBindingSelectorComponent } from "./grouped-binding-selector/grouped-binding-selector.component";
import { ClientTrackingDataComponent } from "./client-tracking-data/client-tracking-data.component";
import { OrderActionsMenuComponent } from "./order-actions-menu/order-actions-menu.component";


@NgModule({
  declarations: [
    ProductQtyComponent,
    PreventDoubleClickDirective,
    OnlyNumberDirective,
    BrowserShareComponent,
    InfoPasarPaginaComponent,
    LoginCardComponent,
    MessageComponent,
    SnowComponent,
    AcceptanceModalComponent,
    ConfirmPopoverComponent,
    ShopCardComponent,
    ValidateDNI,
    UploadFilePopoverComponent,
    AlertModalComponent,
    CircularProgressBarComponent,
    SearchBarComponent,
    MobileToolbarComponent,
    AmbassadorProductsTableComponent,
    CloseModalButtonComponent,
    PrintPermissionPolicyComponent,
    CloseModalButtonComponent,
    BlankSheetsModalComponent,
    PreventClickOnConditionDirective,
    PrintSettingsLabelsComponent,
    ConfirmModalComponent,
    ChangeTextFieldModalComponent,
    GroupedBindingIndividualSettingsComponent,
    IndividualBindingOptionsModalComponent,
    InfoProgramacionDidacticaModalComponent,
    ConfirmModalCartComponent,
    CoverSettingsComponent,
    TooltipInfoDespaginationComponent,
    VideoPlayerModalComponent,
    ConfirmCommentModalComponent,
    OrdersBlockedAlertComponent,
    GroupedBindingSelectorComponent,
    ClientTrackingDataComponent,
    OrderActionsMenuComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    // IonicModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    PipesModule,
    FlexLayoutModule,
    TooltipModule,
    QtyStepperModule,
    OptionsButtonModule,
    BindingOptionsModule
  ],
  exports: [
    ProductQtyComponent,
    PreventDoubleClickDirective,
    OnlyNumberDirective,
    BrowserShareComponent,
    InfoPasarPaginaComponent,
    LoginCardComponent,
    MessageComponent,
    SnowComponent,
    AcceptanceModalComponent,
    ConfirmPopoverComponent,
    ShopCardComponent,
    ValidateDNI,
    UploadFilePopoverComponent,
    CircularProgressBarComponent,
    SearchBarComponent,
    MobileToolbarComponent,
    AlertModalComponent,
    AmbassadorProductsTableComponent,
    CloseModalButtonComponent,
    PreventClickOnConditionDirective,
    PrintSettingsLabelsComponent,
    ClientTrackingDataComponent,
    OrderActionsMenuComponent
  ]
})
export class ComponentsModule {
}
