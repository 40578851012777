import { Pipe, PipeTransform } from '@angular/core';
import {
  OrderTypeStatus,
  OrderProcessStatus,
  OrderShippingStatus,
  OrderPaymentStatus,
  PaymentType,
  PiggyTransactionType,
  PiggyTransactionStatus,
  PiggyTransactionOption
} from "../models/models";

@Pipe({
  name: 'StatusToES'
})
export class StatusToESPipe implements PipeTransform {
  [OrderTypeStatus.PROCESS] = OrderProcessStatus;
  [OrderTypeStatus.SHIPPING] = OrderShippingStatus;
  [OrderTypeStatus.PAYMENT] = OrderPaymentStatus;
  [OrderTypeStatus.PAYTYPE] = PaymentType;
  [OrderTypeStatus.PIGTRANSTYPE] = PiggyTransactionType;
  [OrderTypeStatus.PIGTRANSSTATUS] = PiggyTransactionStatus;
  [OrderTypeStatus.PIGTRANSOPT] = PiggyTransactionOption;

  lang: Array<String> = ['ES'];
  //__________
  readonly processStatusES = {
    PENDING: 'Pendiente',
    PROCESSING: 'Procesando',
    COMPLETE: 'Pte. Recoger',
    CANCELED: 'Cancelado',
    SHIPPED: 'Enviado',
    RETURNED: 'Devuelto',
    PAUSED: 'En Espera',
    ONHOLD: 'Pausado',
    INCIDENCE: 'Incidencia',
  }
  readonly shippingStatusES = {
    PENDING: 'Pendiente',
    ADMITTED: 'Admitido',
    INTRANSIT: 'En Proceso',
    DELIVERED: 'Entregado',
    CANCELED: 'Cancelado',
    RETURNED: 'Devuelto',
  }
  readonly paymentStatusES = {
    PENDING: 'Pago pendiente',
    PROCESSING: 'Pago en proceso', PAID: 'Pagado', FAILED: 'Pago fallido', REFUNDED: 'Reembolsado',
  }
  readonly paymentTypeES = {
    TRANSFER: 'Transferencia Bancaria',
    BIZUM: 'Bizum',
    CREDITCARD: 'Tarjeta de Crédito',
    MONTHLYPAYMENT: 'Pago Mensual / Remesa',
    FULLBONUS: 'Descuento Completo Bono/Cupón',
  }

  // Xav new
  readonly piggyTransactionTypeES = {
    WALLET: "Transferencia Monedero",
    BANK: "Transferencia Bancaria",
    FEE: "Comisión por Venta",
    ROLLBACK: "Reversión por rechazo",
    EXPIRATION: "Comisión expirada"
  }

  readonly piggyTransactionStatusES = {
    PENDING: "Pendiente",
    COMPLETE: "Completado",
    REJECTED: "Rechazado",
    EXPIRED: 'Expirado',  // fee
    ERROR: "Fallido",
  }

  readonly piggyTransactionOptionES = {
    SHARE: 'Compartir',
    REFERRER: 'URL Referrer',
    COUPON: 'Cupón Referrer',
    CREATOR: 'Creador (Impresión)',
    ROYALTY: 'Creador (Contenido)',
  }

  /**
   * @return Una traducción en español de alguno de estas(hay más, ver en concreto):
   * 'processStatus' | 'shippingStatus' | 'paymentStatus' | 'paymentType'
   */
  transform(status: string, type: string): unknown {
    let result = "";
    try {
      result = this[type + this.lang[0]][this[type][status.toUpperCase()].toUpperCase()];
    } catch {
      console.log('Problemas con el estado', { status, type });
    }
    return result;
  }

  /**
   * Devolvemos el valor basado en el tipo y su enum
   */
  getFilterOpt(type: string, enumKeys: {}): any {
    return Object.keys(enumKeys).map(status => {
      return {
        id: status.toLowerCase(),
        label: this.transform(status, type)
      }
    });

  }
}
