import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { EventEmitter, Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection, } from "@angular/fire/compat/firestore";
import { serverTimestamp } from "@angular/fire/firestore";
import { firstValueFrom, Observable, Subject } from "rxjs";
import {
  _PrintFile,
  CoverType,
  Finishing, LaminationType, LaminationWeight,
  PagesPerSheet,
  PaperWeight,
  PrintColor,
  PrintFile,
  PrintingGroup,
  PrintSettings,
  Settings,
} from "../models/models";
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { executeWithRetry } from "./utils/execute-helper";
import { cloneDeep, isMatch } from "lodash";
import { AcceptanceService } from "./acceptance.service";
import firebase from "@firebase/app-compat";

const auth = firebase.auth;

export interface A2GoogleUploadTask {
  percentageChanges(): Observable<number | undefined>;

  then(
    onFulfilled?: ((a: any) => any) | null,
    onRejected?: ((a: Error) => any) | null
  ): Promise<any>;

  catch(onRejected: (a: Error) => any): Promise<any>;
}

@Injectable({
  providedIn: "root",
})
export class FileService {
  private temporaryUpload: AngularFirestoreCollection<PrintFile>;
  private defaultCollection = "uploads";
  coverTypeChangedEvent = new EventEmitter();

  constructor(
    private db: AngularFirestore,
    private httpClient: HttpClient,
    private storage: AngularFireStorage,
    private functions: AngularFireFunctions,
    private acceptanceService: AcceptanceService
  ) {
    //Cambiar aqui lo del path<----------------
    //this.temporaryUpload = this.db.collection<PrintFile>('uploads');
  }

  createId() {
    return this.db.createId();
  }

  addTemporary(id, data, path = this.defaultCollection): Promise<any> {
    const temporaryUpload = this.db.collection<PrintFile>(path);
    data.updatedAt = serverTimestamp();
    data.createdAt = serverTimestamp();
    return temporaryUpload.doc(id).set(data);
  }

  getTemporaryFile(id, path = this.defaultCollection): Observable<_PrintFile> {
    const temporaryUpload = this.db.collection<PrintFile>(path);
    return temporaryUpload.doc<_PrintFile>(id).valueChanges({ idField: "id" });
  }

  delTemporary(id, path = this.defaultCollection): Promise<any> {
    const temporaryUpload = this.db.collection<PrintFile>(path);
    return temporaryUpload.doc(id).delete();
  }

  private coverSettings = [
    {
      value: {
        id: CoverType.Color2Side1Page,
        coverColor: true,
        twoSided: true,
        pagesPerSheet: PagesPerSheet.ONE
      },
      title: 'Portada a color, doble cara',
      img: 'assets/images/coverType/Color2Side1Page.png',
      video: '',
      requirements: [
        { docColor: false, twoSided: true, pagesPerSheet: PagesPerSheet.ONE },
        { docColor: true, twoSided: true, pagesPerSheet: PagesPerSheet.ONE },
      ]
    },

    {
      value: {
        id: CoverType.Color2Side2Page,
        coverColor: true,
        twoSided: true,
        pagesPerSheet: PagesPerSheet.TWO
      },
      title: 'Portada a color, 2 pág por cara, doble cara',
      img: 'assets/images/coverType/Color2Side2Page.png',
      video: '',
      requirements: [
        { docColor: true, twoSided: true, pagesPerSheet: PagesPerSheet.TWO },
      ]
    },
    {
      value: {
        id: CoverType.Color2Side4Page,
        coverColor: true,
        twoSided: true,
        pagesPerSheet: PagesPerSheet.FOUR
      },
      title: 'Portada a color, 4 pág por cara, doble cara',
      img: 'assets/images/coverType/Color2Side4Page.png',
      video: '',
      requirements: [
        { docColor: true, twoSided: true, pagesPerSheet: PagesPerSheet.FOUR },
      ]
    },
    {
      value: {
        id: CoverType.Color1Side1Page,
        coverColor: true,
        twoSided: false,
        pagesPerSheet: PagesPerSheet.ONE
      },
      title: 'Portada a color',
      img: 'assets/images/coverType/Color1Side1Page.png',
      video: '',
      requirements: [
        { docColor: false, twoSided: false, pagesPerSheet: PagesPerSheet.ONE },
        { docColor: false, twoSided: false, pagesPerSheet: PagesPerSheet.TWO },
        { docColor: false, twoSided: false, pagesPerSheet: PagesPerSheet.FOUR },
        { docColor: false, twoSided: true, pagesPerSheet: PagesPerSheet.ONE },
        { docColor: false, twoSided: true, pagesPerSheet: PagesPerSheet.TWO },
        { docColor: false, twoSided: true, pagesPerSheet: PagesPerSheet.FOUR },
        { docColor: true, twoSided: false, pagesPerSheet: PagesPerSheet.ONE },
        { docColor: true, twoSided: false, pagesPerSheet: PagesPerSheet.TWO },
        { docColor: true, twoSided: false, pagesPerSheet: PagesPerSheet.FOUR },
        { docColor: true, twoSided: true, pagesPerSheet: PagesPerSheet.ONE },
        { docColor: true, twoSided: true, pagesPerSheet: PagesPerSheet.TWO },
        { docColor: true, twoSided: true, pagesPerSheet: PagesPerSheet.FOUR }
      ]
    },
    {
      value: {
        id: CoverType.Color1Side2Page,
        coverColor: true,
        twoSided: false,
        pagesPerSheet: PagesPerSheet.TWO
      },
      title: 'Portada a color, 2 pág por cara',
      img: 'assets/images/coverType/Color1Side2Page.png',
      video: '',
      requirements: [
        { docColor: true, twoSided: false, pagesPerSheet: PagesPerSheet.TWO },
      ]
    },
    {
      value: {
        id: CoverType.Color1Side4Page,
        coverColor: true,
        twoSided: false,
        pagesPerSheet: PagesPerSheet.FOUR
      },
      title: 'Portada a color, 4 pág por cara',
      img: 'assets/images/coverType/Color1Side4Page.png',
      video: '',
      requirements: [
        { docColor: true, twoSided: false, pagesPerSheet: PagesPerSheet.FOUR },
      ]
    },
    {
      value: {
        id: CoverType.Black1Side1Page,
        coverColor: false,
        twoSided: false,
        pagesPerSheet: PagesPerSheet.ONE
      },
      title: 'Portada en blanco y negro',
      img: 'assets/images/coverType/Black1Side1Page.png',
      video: '',
      requirements: [
        { docColor: false, twoSided: false, pagesPerSheet: PagesPerSheet.ONE },
        { docColor: false, twoSided: false, pagesPerSheet: PagesPerSheet.TWO },
        { docColor: false, twoSided: false, pagesPerSheet: PagesPerSheet.FOUR },
        { docColor: false, twoSided: true, pagesPerSheet: PagesPerSheet.ONE },
        { docColor: false, twoSided: true, pagesPerSheet: PagesPerSheet.TWO },
        { docColor: false, twoSided: true, pagesPerSheet: PagesPerSheet.FOUR },
      ]
    },
    {
      value: {
        id: CoverType.Black1Side2Page,
        coverColor: false,
        twoSided: false,
        pagesPerSheet: PagesPerSheet.TWO
      },
      title: 'Portada en blanco y negro, 2 pág. por cara',
      img: 'assets/images/coverType/Black1Side2Page.png',
      video: '',
      requirements: [
        { docColor: false, twoSided: false, pagesPerSheet: PagesPerSheet.TWO },
      ]
    },
    {
      value: {
        id: CoverType.Black1Side4Page,
        coverColor: false,
        twoSided: false,
        pagesPerSheet: PagesPerSheet.FOUR
      },
      title: 'Portada en blanco y negro, 4 pág. por cara',
      img: 'assets/images/coverType/Black1Side4Page.png',
      video: '',
      requirements: [
        { docColor: false, twoSided: false, pagesPerSheet: PagesPerSheet.FOUR },
      ]
    },
    {
      value: {
        id: CoverType.Black2Side1Page,
        coverColor: false,
        twoSided: true,
        pagesPerSheet: PagesPerSheet.ONE
      },
      title: 'Portada en blanco y negro, doble cara',
      img: 'assets/images/coverType/Black2Side1Page.png',
      video: '',
      requirements: [
        { docColor: false, twoSided: true, pagesPerSheet: PagesPerSheet.ONE },
      ]
    },
    {
      value: {
        id: CoverType.Black2Side2Page,
        coverColor: false,
        twoSided: true,
        pagesPerSheet: PagesPerSheet.TWO
      },
      title: 'Portada en blanco y negro, 2 pág por cara, doble cara',
      img: 'assets/images/coverType/Black2Side2Page.png',
      video: '',
      requirements: [
        { docColor: false, twoSided: true, pagesPerSheet: PagesPerSheet.TWO },
      ]
    },
    {
      value: {
        id: CoverType.Black2Side4Page,
        coverColor: false,
        twoSided: true,
        pagesPerSheet: PagesPerSheet.FOUR
      },
      title: 'Portada en blanco y negro, 4 pág por cara, doble cara',
      img: 'assets/images/coverType/Black2Side4Page.png',
      video: '',
      requirements: [
        { docColor: false, twoSided: true, pagesPerSheet: PagesPerSheet.FOUR },
      ]
    },
  ]

  laminationOptions = {
    [LaminationType.Matte]: [
      {
        title: 'Flexible',
        value: LaminationWeight.W125
      },
      {
        title: 'Normal',
        value: LaminationWeight.W150
      },
      {
        title: 'Grueso',
        value: LaminationWeight.W250
      }
    ],
    [LaminationType.Gloss]: [
      {
        title: 'Flexible',
        value: LaminationWeight.W80
      },
      {
        title: 'Normal',
        value: LaminationWeight.W125
      },
      {
        title: 'Grueso',
        value: LaminationWeight.W175
      },
      {
        title: 'Extra grueso',
        value: LaminationWeight.W250
      }
    ]
  }

  getCoverSettings() {
    return cloneDeep(this.coverSettings);
  }

  getDefaultCoverType(pFile, printSettings: PrintSettings) {
    const coverColor = pFile.docColor || pFile.coverLaminated
    const twoSided = pFile.coverLaminated ? false : printSettings[Settings.TWO_SIDED]
    const pagesPerSheet = pFile.coverLaminated ? PagesPerSheet.ONE : printSettings[Settings.PAGES_PER_SHEET]
    const paperWeight = pFile.coverType?.paperWeight ?? printSettings[Settings.PAPER_WEIGHT]
    return {
      id: `${coverColor ? 'Color' : 'Black'}${twoSided ? '2Side' : '1Side'}${pagesPerSheet}Page`,
      coverColor,
      twoSided,
      pagesPerSheet,
      paperWeight,
      default: true
    };
  }

  getCoverTypeImage(coverSettings, generalSettings) {
    const result = coverSettings.id + '-' +
      (generalSettings.docColor ? 'Color' : 'Black') +
      (generalSettings.twoSided ? '2' : '1') + 'Side' +
      generalSettings.pagesPerSheet + 'Page.png'
    return `assets/images/coverType/${result}`
  }

  restartCoverTypes(printingGroup, i = null) {
    if (i !== null) {
      const file = printingGroup.files[i];
      file.coverType = {
        ...this.getDefaultCoverType(file, printingGroup.printSettings),
        default: true,
        ...(file.coverType?.paperWeightChanged !== undefined && { paperWeightChanged: file.coverType.paperWeightChanged })

      }
      this.coverTypeChangedEvent.emit(i)
    } else {
      printingGroup.files.forEach((file, index) => {
        file.coverType = {
          ...this.getDefaultCoverType(file, printingGroup.printSettings),
          default: true,
          ...(file.coverType.paperWeightChanged !== undefined && { paperWeightChanged: file.coverType.paperWeightChanged })

        }
        this.coverTypeChangedEvent.emit(index)
      })
    }

  }

  checkCoverTypesWeights(printingGroup: PrintingGroup) {
    printingGroup.files.forEach((file, index) => {

      if (Number(printingGroup.printSettings[Settings.PAPER_WEIGHT]) >= Number(file.coverType.paperWeight)) {
        file.coverType.paperWeight = printingGroup.printSettings[Settings.PAPER_WEIGHT];
      } else {
        file.coverType.paperWeight = file.coverType.paperWeightChanged ?? printingGroup.printSettings[Settings.PAPER_WEIGHT]
      }
      if (file.coverLaminated) {
        file.coverType.paperWeight = PaperWeight.W80
      }
    })

  }

  getLaminationTypeName(type) {
    switch (type) {
      case LaminationType.Matte:
        return 'Mate'
      case LaminationType.Gloss:
        return 'Brillo'
      default:
        return '';
    }
  }

  getLaminationWeightName(option, selectedType) {
    return this.laminationOptions[selectedType].find(el => el.value === option)?.title
  }

  processFile(id, printSettings, collection = "uploads"): Promise<any> {
    if (!(id && printSettings)) {
      return Promise.reject();
    }
    const data = {
      method: "process",
      collection,
      uploadId: id,
      printSettings: printSettings,
    };

    if (this.acceptanceService.getPrintAllowed()) {
      data["printAllowed"] = true;
    }
    const _fn = (data) =>
      this.functions.httpsCallable("processFileUpload")(data).toPromise();
    return executeWithRetry(_fn, { data, maxCount: 3, self: this }).catch(
      (err) => {
        console.error("--- ProcessFile Process ---");
        console.error(err);
        return err;
      }
    );
  }

  updateThumbnail(
    id,
    printSettings,
    collection = "uploads",
    skipProductUpdate = false
  ): Promise<any> {
    const data = {
      method: "thumbnail",
      collection,
      uploadId: id,
      printSettings: printSettings,
      skipProductUpdate,
    };
    const _fn = (data) =>
      firstValueFrom(this.functions.httpsCallable("processFileUpload")(data));
    return executeWithRetry(_fn, { data, maxCount: 3, self: this }).catch(
      (err) => {
        console.error("--- ProcessFile thumbnail ---");
        console.error(err);
        return err;
      }
    );
  }

  //Usado por file Component
  getThumbFile(fileUploaded: _PrintFile, settings: PrintSettings, pFile = null) {
    const _settings = cloneDeep(settings);
    _settings.color = _settings.docColor || _settings.coverColor ? PrintColor.COLOR : PrintColor.BLACKNWHITE;
    _settings.pagesPerSheet = pFile.coverColor && !pFile.docColor ? 1 : settings.pagesPerSheet;
    delete _settings.coverColor
    fileUploaded.thumbs.forEach(th => {
      delete th.printSettings.coverColor
    })
    if (pFile.coverType) {
      _settings.coverColor = pFile.coverType.coverColor;
      _settings.pagesPerSheet = pFile.coverType.pagesPerSheet;
    }
    const thumb = fileUploaded?.thumbs?.find((thumb) =>
      isMatch(_settings, thumb.printSettings)
    );
    return thumb ? thumb.file : null;
  }

  _processFile(id, printSettings): Promise<any> {
    //Procedimiento por Post

    const url = `${environment.apiUrl}upload/` + id;
    if (auth().currentUser) {
      return auth()
        .currentUser.getIdToken(/* forceRefresh */ false)
        .then((idToken) => {
          const headers = new HttpHeaders({
            Authorization: `Bearer ${idToken}`,
            "project-id": `${environment.firebaseConfig.projectId}`,
          });
          const body = { printSettings };
          return this.httpClient
            .request("POST", url, { headers, body })
            .toPromise();
        });
    } else {
      return Promise.reject(new Error("fail-invalid currentUser"));
    }
  }

  _updateThumbnail(id, printSettings): Promise<any> {
    //Procedimiento por Post
    const url = `${environment.apiUrl}upload/` + id + "/thumbnail";
    if (auth().currentUser) {
      return auth()
        .currentUser.getIdToken(/* forceRefresh */ false)
        .then((idToken) => {
          const headers = new HttpHeaders({
            Authorization: `Bearer ${idToken}`,
            "project-id": `${environment.firebaseConfig.projectId}`,
          });
          const body = { printSettings };
          return this.httpClient
            .request("POST", url, { headers, body })
            .toPromise();
        });
    } else {
      return Promise.reject(new Error("fail-invalid currentUser"));
    }
  }

  generateSignedPostPolicy(path): Promise<any> {
    const url = `${environment.apiUrl}upload/generateSignedPostPolicy`;
    if (auth().currentUser) {
      return auth()
        .currentUser.getIdToken(/* forceRefresh */ false)
        .then((idToken) => {
          const headers = new HttpHeaders({
            Authorization: `Bearer ${idToken}`,
            "project-id": `${environment.firebaseConfig.projectId}`,
          });
          const body = { path };
          return this.httpClient
            .request("POST", url, { headers, body })
            .toPromise();
        });
    } else {
      return Promise.reject(new Error("fail-invalid currentUser"));
    }
  }

  getSignedUrl(path, contentType): Promise<any> {
    const url = `${environment.apiUrl}upload/getSignedUrl`;
    if (auth().currentUser) {
      return auth()
        .currentUser.getIdToken(/* forceRefresh */ false)
        .then((idToken) => {
          const headers = new HttpHeaders({
            Authorization: `Bearer ${idToken}`,
            "project-id": `${environment.firebaseConfig.projectId}`,
          });
          const body = { path, contentType };
          return this.httpClient
            .request("POST", url, { headers, body })
            .toPromise();
        });
    } else {
      return Promise.reject(new Error("fail-invalid currentUser"));
    }
  }

  //   POST https://<api server url>/upload/generateSignedPostPolicy
  // {
  //   "path": "patatas/con/queso.pdf",
  // }

  upload(path, file): A2GoogleUploadTask {
    const percentage = new Subject();
    let subscription;
    // percentage.next(0);

    const promise = new Promise((resolve, reject): any => {
      this.getSignedUrl(path, file.type).then((r) => {
        const signedUrl = r.signedUrl;
        const httpOptions = {
          headers: new HttpHeaders(r.headers),
          observe: "events",
          reportProgress: true,
        } as any;

        subscription = this.httpClient
          .put(signedUrl, file, httpOptions)
          .subscribe(
            (event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                percentage.next((event.loaded / event.total) * 100);
              }
            },
            (error) => reject(error),
            () => {
              //Complete
              resolve({
                ref: {
                  getDownloadURL: () =>
                    this.storage.ref(path).getDownloadURL().toPromise(),
                },
              });
            }
          );
      });
    }) as any;

    promise.percentageChanges = () => percentage;
    promise.subscription = () => subscription;
    return promise;
  }
}
