import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { ShoppingCart } from '../models/cart.model';
import {
  _PrintFile,
  DiscountType,
  Finishing, LaminationType, LaminationWeight,
  PageOrientation,
  PageSize,
  PagesPerSheet,
  PageTurn,
  PaperWeight,
  PrintColor,
  PrintingGroup,
  Settings
} from '../models/models';
import { PrintProduct, PrintVariant } from '../models/printProduct.model';
import {
  ExtraPrices,
  HardCoverPrice,
  Product,
  ProductCustomizableElements,
  ProductType,
  RingColorPrice
} from '../models/product.model';
import { AppService } from './app.service';
import { ProductDbService } from './database/product-db.service';
import { BindingHelper } from './helpers/binding';
import { round2 } from "./utils/utils";


// => Precio extra por las anillas según color (en euros)
// importe se suma al precio base de la encuadernación
let RINGCOLORPRICE: RingColorPrice;
let HARDCOVER_PRICE: HardCoverPrice;

// => Precios por acabados
// price (en euros)
// weight (en gramos)
// limit (unidades: folios)
let EXTRAPRICES: ExtraPrices;
// let _EXTRAPRICES: ExtraPrices = {
//   finishing: { //FIXME verificar donde mas Se usa ya que esta deprecado para el finisihing
//     [Finishing.BINDING]: {  // encuadernación
//       limit: 400,  // límite de folios
//       price: 1.49,  // precio base (se le suma el extra por el color de la anilla)
//       description: "Encuadernación",
//       weight: 60
//     },
//     [Finishing.STAPLED]: {
//       limit: null,
//       price: 0,
//       description: "Grapado",
//       weight: 0
//     },
//     [Finishing.NONE]: {
//       limit: null,
//       price: 0,
//       description: "Sin Acabado",
//       weight: 0
//     }
//   },
//   coverColor: { // acabado adicional: portada a color en grupo BYN
//     true: {
//       price: 0.22, // precio adicional al base COLOR (por página impresa: 1 sola, esa portada)
//       description: "Portada a Color",
//     },
//     false: {
//       price: 0,
//       description: "Portada B/N", // this is not used
//     }
//   },
//   docColor: { // acabado adicional: documento a COLOR en grupo BYN
//     true: {
//       // price: 0.015, // precio adicional al base COLOR (por página impresa)
//       price: 0.020, // precio adicional al base COLOR (por página impresa)
//       description: "Documento a Color",
//     },
//     false: {
//       price: 0,
//       description: "Documento BN", // this is not used
//     }
//   }
// }


// => Productos: tienda y bonos
// const PRODUTCS: ShoppingCart.OrderItemUi[] = [{
//     cartId:null,
//     product: {
//       id: "1",
//       type: ProductType.STORE,
//       listOrder: 30,
//       title: "Stabilo Pastel 6 subrayadores",
//       description: "Stabilo Boss original Pastel - Subrayador - Estuche 6 colores.",
//       unitPrice: {
//         amt: 5.950,
//         cur: 'EUR'
//       }, // page price? encuadernaciones dónde van? (otro producto?) // includes tax
//       pictureUrl: 'assets/images/pack-6-stabilo-pastel.jpg',
//       weight: 136
//     },
//     qty: 0,
//     price: {
//       amt: 0,
//       cur: 'EUR'
//     },
//   },
//   // {
//   //   product: {
//   //     id: "2",
//   //     type: ProductType.STORE,
//   //     listOrder: 18,
//   //     title: "Paquete de 500 Folios Copyfly 80g",
//   //     description: "Paquete de 500 hojas de papel tamaño DIN A4 de 80g. Papel multifunción de alta calidad.",
//   //     unitPrice: {
//   //       amt: 3.500,
//   //       cur: 'EUR'
//   //     },
//   //     pictureUrl: 'assets/images/paquete-de-folios.jpg',
//   //     weight: 2450
//   //   },
//   //   qty: 0,
//   //   price: {
//   //     amt: 0,
//   //     cur: 'EUR'
//   //   },
//   // },

//   {
//     cartId:null,
//     product: {
//       id: "6",
//       type: ProductType.STORE,
//       listOrder: 20,
//       title: "Stabilo Pack: 9 Fluo + 6 Pastel + Expositor",
//       description: "9 subrayadores fluorescentes de Stabilo, 6 subrayadores pastel de Stabilo y Expositor para los subrayadores.",
//       unitPrice: {
//         amt: 16.000,
//         cur: 'EUR'
//       },
//       pictureUrl: 'assets/images/stabilo-expositor.jpg',
//       weight: 381
//     },
//     qty: 0,
//     price: {
//       amt: 0,
//       cur: 'EUR'
//     }
//   },
//   {
//     cartId:null,
//     product: {
//       id: "7",
//       type: ProductType.STORE,
//       listOrder: 40,
//       title: "Boli Bic Cristal Azul - Unidad",
//       description: "Bolígrafo Bic cristal azul. Trazo de 0,4mm.",
//       unitPrice: {
//         amt: 0.300,
//         cur: 'EUR'
//       },
//       pictureUrl: 'assets/images/bic-individual-azul.jpg',
//       weight: 4.00
//     },
//     qty: 0,
//     price: {
//       amt: 0,
//       cur: 'EUR'
//     }
//   },
//   {
//     cartId:null,
//     product: {
//       id: "8",
//       type: ProductType.STORE,
//       listOrder: 50,
//       title: "Boli Bic Cristal Negro - Unidad",
//       description: "Bolígrafo Bic cristal negro. Trazo de 0,4mm",
//       unitPrice: {
//         amt: 0.300,
//         cur: 'EUR'
//       },
//       pictureUrl: 'assets/images/bic-individual-negro.jpg',
//       weight: 4.00
//     },
//     qty: 0,
//     price: {
//       amt: 0,
//       cur: 'EUR'
//     }
//   },
//   {
//     cartId:null,
//     product: {
//       id: "9",
//       type: ProductType.STORE,
//       listOrder: 60,
//       title: "Stabilo Boss Original - Unidad",
//       description: "Subrayador fluorescente, punta biselada, trazo de 2 a 5 mm, color amarillo.",
//       unitPrice: {
//         amt: 1.150,
//         cur: 'EUR'
//       },
//       pictureUrl: 'assets/images/stabilo-amarillo-individual.jpg',
//       weight: 20.0
//     },
//     qty: 0,
//     price: {
//       amt: 0,
//       cur: 'EUR'
//     }
//   },
//   {
//     cartId:null,
//     product: {
//       id: "Z9999999999",
//       type: ProductType.GENERIC,
//       listOrder: 900,
//       title: "",
//       description: "Producto genérico",
//       unitPrice: {
//         amt: 0,
//         cur: 'EUR'
//       },
//       pictureUrl: '',
//       weight: 0
//     },
//     qty: 0,
//     price: {
//       amt: 0,
//       cur: 'EUR'
//     }
//   },
//   // {
//   //   product: {
//   //     id: "10",
//   //     type: ProductType.STORE,
//   //     listOrder: 5,
//   //     title: "Disco duro externo Toshiba 1TB USB 3.0",
//   //     description: "Toshiba Canvio Basics, disco duro externo portátil USB 3.0 de 2.5 pulgadas (1 TB).",
//   //     unitPrice: {
//   //       amt: 39.900,
//   //       cur: 'EUR'
//   //     },
//   //     pictureUrl: 'assets/images/toshiba-canvio-basics-usb-1tb.jpg',
//   //     weight: 149
//   //   },
//   //   qty: 0,
//   //   price: {
//   //     amt: 0,
//   //     cur: 'EUR'
//   //   }
//   // },
//   // {
//   //   product: {
//   //     id: "11",
//   //     type: ProductType.STORE,
//   //     listOrder: 10,
//   //     title: "Paquete de 500 Folios Navigator 80g",
//   //     description: "Paquete de 500 hojas de papel tamaño DIN A4 de 80g. Papel multifunción de alta calidad.",
//   //     unitPrice: {
//   //       amt: 4.590,
//   //       cur: 'EUR'
//   //     },
//   //     pictureUrl: 'assets/images/paquete-de-folios-navigator-80g.jpg',
//   //     weight: 2540
//   //   },
//   //   qty: 0,
//   //   price: {
//   //     amt: 0,
//   //     cur: 'EUR'
//   //   },
//   // },
//   // {
//   //   product: {
//   //     id: "12",
//   //     type: ProductType.STORE,
//   //     listOrder: 14,
//   //     title: "Paquete de 500 Folios Navigator 100g",
//   //     description: "Paquete de 500 hojas de papel tamaño DIN A4 de 100g. Papel multifunción de alta calidad.",
//   //     unitPrice: {
//   //       amt: 6.990,
//   //       cur: 'EUR'
//   //     },
//   //     pictureUrl: 'assets/images/paquete-de-folios-navigator-100g.jpg',
//   //     weight: 3148
//   //   },
//   //   qty: 0,
//   //   price: {
//   //     amt: 0,
//   //     cur: 'EUR'
//   //   },
//   // },
// ]

// => Disponibilidad de descuentos según tipo de producto
const DISCOUNTS = {
  [ProductType.PRINT]: {
    [DiscountType.COUPON]: true,
    [DiscountType.WALLET]: true,
  },
  [ProductType.STORE]: {
    [DiscountType.COUPON]: false,
    [DiscountType.WALLET]: true,
  },
  [ProductType.WALLETBONUS]: {
    [DiscountType.COUPON]: false,
    [DiscountType.WALLET]: false,
  },
  [ProductType.GENERIC]: {
    [DiscountType.COUPON]: false,
    [DiscountType.WALLET]: true,
  },
}

@Injectable({
  providedIn: 'root'
})

export class ProductService {
  private isInit = new BehaviorSubject<boolean>(false);
  public isInit$ = this.isInit.asObservable();
  weights: Array<PrintVariant> = [];
  tablePrices: Array<PrintVariant> = [];
  discounts = DISCOUNTS;
  printProduct: PrintProduct;
  productType = ProductType
  printProductDb$: Observable<Product> = this.productDbService.get$('0').pipe(shareReplay(1))
  bindingHelper: BindingHelper
  blankSheetPercentageCalc = 100;
  papers$;
  customizablePapers$;
  combinedPapers;
  productOpened = null;
  cartStepsOpened: boolean = false;

  constructor(
    private appService: AppService,
    private productDbService: ProductDbService
  ) {
    this.printProductDb$.subscribe(p => {
      this.tablePrices = p.variants as Array<PrintVariant>;
      this.printProduct = p;
      delete this.printProduct.variants;
      this.weights = p.weights
      delete this.printProduct.weights;

      this.blankSheetPercentageCalc = this.printProduct.extraPrices.blankSheetPercentageCalc;
      const bindingData = this.printProduct.bindingData
      delete this.printProduct.bindingData;
      this.bindingHelper = new BindingHelper(bindingData);

      RINGCOLORPRICE = this.printProduct.ringColorPrice;
      delete this.printProduct.ringColorPrice;
      HARDCOVER_PRICE = this.printProduct.hardCoverPrice;
      delete this.printProduct.hardCoverPrice;
      EXTRAPRICES = this.printProduct.extraPrices;
      delete EXTRAPRICES.finishing.binding.weight //Evaluando pero creo que ya no se necesita
      delete this.printProduct.extraPrices;

      const ringColors = this.printProduct.ringColors
      delete this.printProduct.ringColors;
      const hardCovers = this.printProduct.hardCovers
      delete this.printProduct.hardCovers;
      this.appService.setRingsCoversSettings(ringColors, hardCovers);

      this.isInit.next(true);


      this.papers$ = productDbService.internalProductWithVariants$('papers');
      this.customizablePapers$ = productDbService.internalProductWithVariants$('customizablePapers');
      combineLatest(this.papers$, this.customizablePapers$).pipe(
        map(([papers, customizablePapers]) => {
          this.combinedPapers = this.mergeVariants(papers, customizablePapers);
        }),
        shareReplay(1)
      ).subscribe();

    })
  }

  public newPrintProductItem() {
    const printProduct = cloneDeep(this.printProduct);
    const item = ShoppingCart.newOrderItem(printProduct, 1)
    //tenemos que agregar las cosas del item
    // console.log("product service Item", item);
    return item;
  }

  public newPrintProduct() {
    const newPrintProduct = {
      ...cloneDeep(this.printProduct),
      title: '', description: '', slug: null,
      seo: { title: '', description: '' },
      royalty: 0,
      allowSaleNoStock: true, requiresShipping: false
    };
    // newPrintProduct.title = '';
    // newPrintProduct.description = '';
    // newPrintProduct.slug = '';
    // newPrintProduct.seo = {title: '', description: ''};
    // newPrintProduct.allowSaleNoStock = true;
    // newPrintProduct.requiresShipping = false;      
    return newPrintProduct;
  }


  getTotalSides(pages, settings, coverType) {
    //Si se seleccionó por ambas caras se debe agregar una página si es impar  // MN: not anymore
    const coverSides = coverType.twoSided ? 2 : 1;
    let tmpSides = Math.ceil((pages - (coverSides * coverType.pagesPerSheet)) / (settings.pagesPerSheet * 1));
    tmpSides += coverSides;
    return tmpSides
  }

  getFileSheets(sides, settings, coverType) {
    const coverSides = coverType.twoSided ? 2 : 1;
    return (settings.twoSided ? Math.ceil((sides - coverSides) / 2) : sides - coverSides) + 1; // Los folios del documento más el folio de la "portada"
  }

  getFilePrice(sheets, settings, coverType, blankSheets = 0, file) {

    //Lleagn los sheets, descontando la portada
    const isColor = settings[Settings.COLOR] == PrintColor.COLOR
    const biggerFormat = {
      [PageSize.A3]: PageSize.A3,
      [PageSize.A4]: PageSize.A3,
      [PageSize.A5]: PageSize.A4,
    }

    const laminationType = file.laminationType ?? settings[Settings.LAMINATION_TYPE] ?? LaminationType.Gloss;
    const laminationWeight = file.laminationWeight ?? settings[Settings.LAMINATION_WEIGHT] ?? LaminationWeight.W125;
    const coverLaminatedPrice = EXTRAPRICES[Settings.COVER_LAMINATED][settings[Settings.PAGE_SIZE]]?.price +
      (EXTRAPRICES.finishing[Finishing.LAMINATED].variants.find(el => el.options.laminationType === laminationType
        && el.options.laminationWeight === laminationWeight && el.options.pageSize === biggerFormat[settings[Settings.PAGE_SIZE]]).price);


    if (settings[Settings.DOC_COLOR] && !isColor) { // se cobra a color, y abajo se le suma un extra
      settings[Settings.COLOR] = PrintColor.COLOR;
    }

    if (!settings[Settings.DOC_COLOR] && isColor) { // se cobra a byn aunque el setting de fuera es color
      settings[Settings.COLOR] = PrintColor.BLACKNWHITE;
    }

    if (sheets === 1) {
      settings[Settings.TWO_SIDED] = false;
    }
    const price = this.getPriceOption(settings);

    // console.log("#Precio por cara", price)
    const coverSides = coverType.twoSided ? 2 : 1;
    let subTotalDocument = Math.max(0, (sheets - coverSides)) * price + (file.coverLaminated ? coverLaminatedPrice : 0);
    // console.log("#Precio file", subTotal)
    // NEW: MN: add extra price caused by color cover setting
    // if (settings[Settings.COVER_COLOR] && !isColor) {
    //   subTotal += EXTRAPRICES[Settings.COVER_COLOR][settings[Settings.COVER_COLOR]].price;
    // }
    const coverSettings = cloneDeep(settings);
    coverSettings[Settings.COLOR] = coverType.coverColor ? PrintColor.COLOR : PrintColor.BLACKNWHITE;
    coverSettings[Settings.TWO_SIDED] = sheets === 1 ? false : coverType.twoSided;
    coverSettings[Settings.PAGES_PER_SHEET] = coverType.pagesPerSheet;
    coverSettings[Settings.PAPER_WEIGHT] = file.coverLaminated ? settings[Settings.PAPER_WEIGHT] : coverType.paperWeight;

    const priceCover = this.getPriceOption(coverSettings) * Math.min(coverSides, sheets);
    let subTotal = subTotalDocument + priceCover

    if (coverType.coverColor && settings[Settings.COLOR] === PrintColor.BLACKNWHITE) {
      const extraPrice = settings[Settings.COVER_LAMINATED] ? 0
        : EXTRAPRICES[Settings.COVER_COLOR][settings[Settings.COVER_COLOR]].price
      subTotal += extraPrice
    }


    // add extra price for printing docColor in a BYN group (price per side printed!)
    if ((settings[Settings.DOC_COLOR] && !isColor) && !(settings[Settings.COVER_COLOR] && sheets == 1)) {
      const extraDocColor = sheets * EXTRAPRICES[Settings.DOC_COLOR][settings[Settings.DOC_COLOR]].price;
      // console.log("#extraDocColor", extraDocColor);
      subTotal += extraDocColor;
    }

    // add skip fit to page extra
    if (settings[Settings.SKIP_FIT_TO_PAGE]) {
      const extraPrice = sheets * (EXTRAPRICES[Settings.SKIP_FIT_TO_PAGE]?.[settings[Settings.SKIP_FIT_TO_PAGE]]?.price ?? 0);
      // console.log("#extraPrice SKIP_FIT_TO_PAGE", extraPrice);
      subTotal += extraPrice;
    }

    if (blankSheets) {
      const extraBlankSheets = blankSheets * (this.getPriceOption(settings) * (this.blankSheetPercentageCalc / 100));
      subTotal += extraBlankSheets;
    }
    return subTotal;
  }

  getFinishing(fullSheets, settings, printingGroup, file = null) {
    let option = settings[Settings.FINISHING];
    let description = EXTRAPRICES.finishing[option].description ?? '';

    // si estaba em Finishing.BINDING , settings[Settings.FINISHING]
    if (settings[Settings.FINISHING] == Finishing.BINDING || settings[Settings.FINISHING] == Finishing.BINDINGX) {

      /// EVEN OLDER:
      // let g = this.getLabelSetting({key: Settings.GROUPED , value: settings[Settings.GROUPED]}, settings );
      // let r_c = this.getLabelSetting({key: Settings.RING_COLOR, value: settings[Settings.RING_COLOR]}, settings );

      /// OLD:
      // // this undefined check is for backwards compatibility (grouped was default when this setting didn't exist)
      // let g = settings[Settings.GROUPED] == true || settings[Settings.GROUPED] === undefined ? 'Agrupado' : 'Individual';
      // let r_c = `Anillas ${settings[Settings.RING_COLOR].name}`;
      // description = `${EXTRAPRICES.finishing[option].description}, ${g}, ${r_c}`;
      // // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@", description, settings);

      /// NEW: MN
      const labels = [description];
      // this undefined check is for backwards compatibility (grouped was default when this setting didn't exist)
      labels.push(settings[Settings.GROUPED] == true || settings[Settings.GROUPED] === undefined ? 'Agrupado' : 'Individual');
      labels.push(`Anillas ${settings[Settings.RING_COLOR].name}`);
      // backwards compatibility: saved orders won't have these:
      if (settings[Settings.HARD_COVER_FRONT]) {
        labels.push(`T. Delantera ${settings[Settings.HARD_COVER_FRONT].name}`);
      }
      if (settings[Settings.HARD_COVER_BACK]) {
        labels.push(`T. Trasera ${settings[Settings.HARD_COVER_BACK].name}`);
      }
      description = labels.join(', ');

      /*
      option => none
      description => Sin Acabado
      settings => *Todos los valores del setting*
      */
    }

    /**
     * TODO
     * - Que cuando sea encuadernación se muestre
     *  en la descripcion el tipo de encuadernación
     *  y el color escogido
     */

      //Se divide el total depáginas entre el limite del acabado para determinar el nro de acabados;
      // const units = Math.ceil(EXTRAPRICES.finishing[option].limit ? sheets/EXTRAPRICES.finishing[option].limit: 1);
      // MN: FIX: total bindings depend on printing sides
      // const fullSheets = settings.twoSided ? Math.ceil( sheets / 2 ) : sheets;
    let units = Math.ceil(EXTRAPRICES.finishing[option].limit ? fullSheets / EXTRAPRICES.finishing[option].limit : 1);

    //Agregamos el precio de color de las anillas
    const color = settings['ringColor']?.name || null;
    let ringPrice = 0;
    let hardCoverFrontPrice = 0;
    let hardCoverBackPrice = 0;
    let laminationPrice = 0;

    const getHardCoverColorPrice = (hardCover: Settings.HARD_COVER_FRONT | Settings.HARD_COVER_BACK) => {
      let price = HARDCOVER_PRICE.default;
      let hardCoverColorId;
      let exclusive
      if (settings[Settings.GROUPED]) {
        hardCoverColorId = settings[hardCover]?.id ?? null;
        exclusive = settings[hardCover]?.exclusive ?? false;
      } else {
        hardCoverColorId = file[hardCover]?.id ?? null;
        exclusive = file[hardCover]?.exclusive ?? false;
      }
      if (hardCoverColorId && this.appService.getPrintSettingDefault(hardCover).id !== hardCoverColorId) {
        price = HARDCOVER_PRICE.hasOwnProperty(hardCoverColorId)
          ? HARDCOVER_PRICE[hardCoverColorId]
          : exclusive ? HARDCOVER_PRICE.exclusive : HARDCOVER_PRICE.noDefault;
      }
      return price;
    }

    const getLaminationPrice = () => {
      if (file) {
        return (EXTRAPRICES.finishing[Finishing.LAMINATED].variants.find(el => el.options.laminationType === file.laminationType
          && el.options.laminationWeight === file.laminationWeight && el.options.pageSize === settings[Settings.PAGE_SIZE]).price) * units
      } else {
        let price = 0
        printingGroup.files.forEach(file => {
          const _units = Math.ceil(EXTRAPRICES.finishing[option].limit ? file.printSheets / EXTRAPRICES.finishing[option].limit : 1);
          price += (EXTRAPRICES.finishing[Finishing.LAMINATED].variants.find(el => el.options.laminationType === file.laminationType
            && el.options.laminationWeight === file.laminationWeight && el.options.pageSize === settings[Settings.PAGE_SIZE]).price) * _units
        })
        return price
      }
    }

    const ringColorId = settings['grouped'] ? settings['ringColor']?.id : file['ringColor']?.id ?? null;
    if (option === Finishing.BINDING) {
      // console.log("--->color", color)
      ringPrice = RINGCOLORPRICE.hasOwnProperty(ringColorId) ? RINGCOLORPRICE[ringColorId] : RINGCOLORPRICE.Default;
      hardCoverFrontPrice = this.getHardCoverColorPrice(settings[Settings.HARD_COVER_FRONT], Settings.HARD_COVER_FRONT);
      hardCoverBackPrice = this.getHardCoverColorPrice(settings[Settings.HARD_COVER_BACK], Settings.HARD_COVER_BACK);
    }


    //LAMINATION PRICE
    if (option === Finishing.LAMINATED) {
      laminationPrice = getLaminationPrice();
    }

    let price = (((EXTRAPRICES.finishing[option].variants ? 0 : EXTRAPRICES.finishing[option].price) + ringPrice + hardCoverFrontPrice + hardCoverBackPrice) * units) + laminationPrice;
    if (EXTRAPRICES.finishing[option].minPrice && EXTRAPRICES.finishing[option].minPrice > price) {
      price = EXTRAPRICES.finishing[option].minPrice;
    }

    return {
      description,
      units,
      price,
    }
  }

  getHardCoverColorPrice(hardCover, side: Settings.HARD_COVER_FRONT | Settings.HARD_COVER_BACK) {
    let price = HARDCOVER_PRICE.default;
    const hardCoverColorId = hardCover?.id ?? null;
    const exclusive = hardCover?.exclusive ?? false;
    if (hardCoverColorId && this.appService.getPrintSettingDefault(side).id !== hardCoverColorId) {
      price = HARDCOVER_PRICE.hasOwnProperty(hardCoverColorId)
        ? HARDCOVER_PRICE[hardCoverColorId]
        : exclusive ? HARDCOVER_PRICE.exclusive : HARDCOVER_PRICE.noDefault;
    }
    return price;
  }

  getRingColorPrice(ringColorId) {
    return RINGCOLORPRICE.hasOwnProperty(ringColorId) ? RINGCOLORPRICE[ringColorId] : RINGCOLORPRICE.Default;
  }

  async getCustomProductPrice(basePrice, optionsSelected, product?) {
    await this.initializeCombinedPapers();
    let price = basePrice;
    const auxOptionsSelected = cloneDeep(optionsSelected);
    if (optionsSelected?.paperSize && optionsSelected.paperColor && optionsSelected.paperWeight && optionsSelected.paperPattern) {
      auxOptionsSelected.paperFormat = {
        paperSize: optionsSelected.paperSize,
        paperColor: optionsSelected.paperColor,
        paperWeight: optionsSelected.paperWeight,
        paperPattern: optionsSelected.paperPattern
      }
      delete auxOptionsSelected.paperSize;
      delete auxOptionsSelected.paperColor;
      delete auxOptionsSelected.paperWeight;
      delete auxOptionsSelected.paperPattern;
    }

    Object.entries(auxOptionsSelected)?.forEach(([key, value]: [string, any]) => {
      if (!value) {
        return;
      }
      switch (key) {
        case ProductCustomizableElements.PAPER_FORMAT :
          if (auxOptionsSelected.sheetNumber) {
            //console.log(this.combinedPapers);
            const paperFormat = this.combinedPapers.find(pap =>
              pap.properties.paperWeight === auxOptionsSelected.paperFormat.paperWeight.value &&
              pap.properties.paperColor === auxOptionsSelected.paperFormat.paperColor.value &&
              pap.properties.paperPattern === auxOptionsSelected.paperFormat.paperPattern.value &&
              pap.properties.paperSize === auxOptionsSelected.paperFormat.paperSize.value
            );
            if (paperFormat) {
              //let paperPrice =
              let paperFound = product.customElements.find(item => item.id === 'paperFormat')?.values.find(paper =>
                paper.paperWeight === paperFormat.properties.paperWeight &&
                paper.paperColor === paperFormat.properties.paperColor &&
                paper.paperPattern === paperFormat.properties.paperPattern &&
                paper.paperSize === paperFormat.properties.paperSize
              );
              //price += (paperFormat.unitPrice.amt ?? 0) * auxOptionsSelected.sheetNumber.value
              price += (paperFound.price ?? 0) * auxOptionsSelected.sheetNumber.value
            }
          }
          break;
        case ProductCustomizableElements.RINGS :
          price += this.getRingColorPrice(value.id)
          break;
        case ProductCustomizableElements.COVER_FRONT :
        case ProductCustomizableElements.COVER_BACK :
          price += this.getHardCoverColorPrice(value, (key === ProductCustomizableElements.COVER_FRONT ? Settings.HARD_COVER_FRONT : Settings.HARD_COVER_BACK))
          break;
      }
    })
    return price;
  }

  async getCustomProductWeight(optionsSelected) {
    await this.initializeCombinedPapers();
    let weight = 0;
    let pageTurn = PageTurn.LARGESIDE;
    const auxOptionsSelected = cloneDeep(optionsSelected);
    if (optionsSelected.paperSize && optionsSelected.paperColor && optionsSelected.paperWeight && optionsSelected.sheetNumber && optionsSelected.paperPattern) {
      auxOptionsSelected.paperFormat = {
        paperWeight: optionsSelected.paperWeight,
        paperColor: optionsSelected.paperColor,
        paperPattern: optionsSelected.paperPattern,
        paperSize: optionsSelected.paperSize
      }
      delete auxOptionsSelected.paperSize;
      delete auxOptionsSelected.paperColor;
      delete auxOptionsSelected.paperPattern;
      delete auxOptionsSelected.paperWeight;
    } else {
      return null
    }

    Object.entries(auxOptionsSelected).forEach(([key, value]: [string, any]) => {
      if (!value) {
        return;
      }
      switch (key) {
        case ProductCustomizableElements.PAPER_FORMAT :
          const paperFormat = this.combinedPapers.find(pap =>
            pap.properties.paperSize === auxOptionsSelected.paperFormat.paperSize.value
            && pap.properties.paperColor === auxOptionsSelected.paperFormat.paperColor.value
            && pap.properties.paperWeight === auxOptionsSelected.paperFormat.paperWeight.value
            && pap.properties.paperPattern === auxOptionsSelected.paperFormat.paperPattern.value
          )
          if (paperFormat) {
            //parche forzar precio igual al de los folios en impresiones
            weight += this.weights[auxOptionsSelected.paperFormat.paperSize.value][auxOptionsSelected.paperFormat.paperWeight.value] * (auxOptionsSelected.sheetNumber.value)
            // weight += (paperFormat.weight ?? 0) * auxOptionsSelected.sheetNumber.value;
          }
          break;
        case ProductCustomizableElements.RINGS :
          weight += this.bindingHelper.findBindingRing(auxOptionsSelected.sheetNumber.value, auxOptionsSelected.paperFormat.paperSize.value,
            auxOptionsSelected.paperFormat.paperWeight.value, pageTurn).weight
          break;
      }
    })
    return weight;
  }

  findBindingRing(sheetNumber, paperSize, paperWeight, pageTurn = PageTurn.LARGESIDE) {
    return this.bindingHelper.findBindingRing(sheetNumber, paperSize, paperWeight, pageTurn)
  }

  getFinishingData(type) {
    return EXTRAPRICES.finishing[type]
  }

  getPrintingGroupSheetsWeight(printingGroup: PrintingGroup) {
    //Para los casos viejos donde no se consideraba el Gramaje
    let weight = 0;
    if (!printingGroup.printSettings.paperWeight) {
      printingGroup.printSettings.paperWeight = PaperWeight.W80;
    }
    const files = printingGroup.files;
    for (const file of files) {
      const coverWeight = this.weights[printingGroup.printSettings.pageSize][file.coverType.paperWeight]
      const documentWeight = this.weights[printingGroup.printSettings.pageSize][printingGroup.printSettings.paperWeight] * (file.printSheets - 1)
      weight += (coverWeight + documentWeight)
    }
    return weight;
  }

  getProductWeight(product: Product._Product) {
    return product.type === ProductType.PRINT
      ? (product.printingGroup.weight ?? this.getPrintWeight(product))
      : product.weight;
  }

  getPrintWeight(product) {
    //     product.printingGroup.weight
    let weight = 0;
    //JA Parche hasta evaluar los casos de weight en Nan. En teoría no debería pasar, pero hay ordenes que se están creando sin peso.
    if (!product.printingGroup.printSettings.paperWeight) {
      product.printingGroup.printSettings.paperWeight = PaperWeight.W80;
    }
    //Obtenemos el peso en gramos de la hoja, sin embargo mantenemos el método viejo que era único (para 80g)
    const wP = this.weights[product.printingGroup.printSettings.pageSize][product.printingGroup.printSettings.paperWeight];  //DEbemos ver como manjeamos lo scasos viejos

    // const wP = product.printingGroup.printSettings.paperWeight ?
    //   this.weights[product.printingGroup.printSettings.pageSize][product.printingGroup.printSettings.paperWeight] :
    //   this.weights[product.printingGroup.printSettings.pageSize];
    //Fin peso de la hoja


    // let pages = product.printingGroup.files.reduce((acc, file )=> (acc += file.pages),0);
    // weight = pages * wP;
    const sheets = product.printingGroup.printSheets;

    weight = sheets * wP;
    const finishing = product.printingGroup.printSettings.finishing;
    const extra = product.extra;


    const fWeight = this.getFinishingWeight(finishing, product, false);
    weight += fWeight;
    return weight;
  }

  getFinishingWeight(finishing, product, extra = true) {

    if (finishing !== Finishing.LAMINATED) {
      if (extra) {
        return EXTRAPRICES.finishing[finishing].weight * product.printingGroup.printSheets;
      } else {
        return (product.extra?.units ? product.extra.units : 1) * EXTRAPRICES.finishing[finishing].weight;
      }
    } else {
      let _weight = 0;
      product.printingGroup.files.forEach(file => {
        _weight += (EXTRAPRICES.finishing[finishing].variants.find(el => el.options.laminationType === file.laminationType
          && el.options.laminationWeight === file.laminationWeight &&
          el.options.pageSize === product.printingGroup.printSettings[Settings.PAGE_SIZE]).weight) * file.printSheets
      })
      return _weight
    }
  }

  /**
   * Devuelve el valor por defecto del Producto
   * @param setting option del setting que se requiere el valor por defecto
   */
  getDefaultSettingOption(setting) {
    return this.printProduct.printingGroup.printSettings[setting];
  }

  // keep this: same funtions as in other 2 repos (admin and server)
  getPrintLabels(settings) {
    let labels = [];
    let keys = [ // force order to be shown
      Settings.PAGE_SIZE, Settings.PAPER_WEIGHT, Settings.COLOR,
      Settings.TWO_SIDED, Settings.PAGES_PER_SHEET, Settings.AUTO_ROTATE,
      Settings.PAGE_ORIENTATION, Settings.PAGE_TURN, Settings.FINISHING,
    ];
    const labelSetting = {
      [Settings.PAGE_SIZE]: {
        [PageSize.A3]: "A3",
        [PageSize.A4]: "A4",
        [PageSize.A5]: "A5"
      },
      [Settings.PAPER_WEIGHT]: {
        [PaperWeight.W80]: "80 g/m2",
        [PaperWeight.W100]: "100 g/m2",
        [PaperWeight.W300]: "300 g/m2"
      },
      [Settings.PAGE_ORIENTATION]: {
        [PageOrientation.LANDSCAPE]: "Folio: Horizontal",
        [PageOrientation.PORTRAIT]: "Folio: Vertical",
      },
      [Settings.COLOR]: {
        [PrintColor.BLACKNWHITE]: "B/N",
        [PrintColor.COLOR]: "Color",
      },
      [Settings.PAGES_PER_SHEET]: {
        [PagesPerSheet.ONE]: "1 Pág. x Cara",
        [PagesPerSheet.TWO]: "2 Pág. x Cara",
        [PagesPerSheet.FOUR]: "4 Pág. x Cara",
      },
      [Settings.TWO_SIDED]: {
        true: "Doble Cara",
        false: "Una Cara",
      },
      [Settings.PAGE_TURN]: {
        [PageTurn.SHORTSIDE]: "Pasar Pág: Borde Corto",
        [PageTurn.LARGESIDE]: "Pasar Pág: Borde Largo"
      },
      [Settings.FINISHING]: {
        [Finishing.BINDING]: "Encuadernación",
        [Finishing.BINDINGX]: "Encuadernación lado corto", // backward compatibility
        [Finishing.STAPLED]: "Grapado",
        [Finishing.LAMINATED]: "Plastificado",
        [Finishing.PERFORATED4]: "Taladrado 4 agujeros",
        [Finishing.PERFORATED2]: "Taladrado 2 agujeros",
        [Finishing.NONE]: "Sin Acabado",
      },
      [Settings.GROUPED]: {
        true: "Agrupados",
        false: "Individuales",
      },
      [Settings.COVER_COLOR]: {
        false: "", // no se usa
        true: "Portada a Color"
      },
      [Settings.COVER_LAMINATED]: {
        false: "", // no se usa
        true: "Portada plastificada a color"
      },
      [Settings.DOC_COLOR]: {
        false: "", // no se usa
        true: "Doc. a Color"
      },
      [Settings.AUTO_ROTATE]: {
        false: "Rotación Automática: No",
        true: "Rotación Automática: Sí"
      },
    };

    keys.forEach(e => {
      if (settings[e] === undefined) {
        return; // backward compatible: setting may not exist in old orders
      }
      let label;
      label = labelSetting[e][settings[e]];
      if (e == Settings.COLOR && settings[Settings.COLOR] == PrintColor.BLACKNWHITE) {
        // some times settings[Settings.COVER_COLOR] = true even though PrintColor.COLOR!
        if (settings[Settings.COVER_COLOR] && settings[Settings.DOC_COLOR] && !settings[Settings.COVER_LAMINATED]) {
          label += ` (Mixto)`;
        } else if (settings[Settings.COVER_COLOR] && settings[Settings.DOC_COLOR] && settings[Settings.COVER_LAMINATED]) {
          label += ` (Mixto y portada plastificada)`;
        } else if (settings[Settings.COVER_COLOR]) {
          if (settings[Settings.COVER_LAMINATED]) {
            label += ` (${labelSetting[Settings.COVER_LAMINATED][settings[Settings.COVER_LAMINATED]]})`;
          } else {
            label += ` (${labelSetting[Settings.COVER_COLOR][settings[Settings.COVER_COLOR]]})`;
          }
        } else if (settings[Settings.DOC_COLOR]) {
          if (settings[Settings.COVER_LAMINATED]) {
            label += ` (${labelSetting[Settings.DOC_COLOR][settings[Settings.DOC_COLOR]]} y ${labelSetting[Settings.COVER_LAMINATED][settings[Settings.COVER_LAMINATED]]})`;
          } else {
            label += ` (${labelSetting[Settings.DOC_COLOR][settings[Settings.DOC_COLOR]]})`;
          }
        }
      }
      if (e == Settings.COLOR && settings[Settings.COLOR] == PrintColor.COLOR) {
        if (settings[Settings.COVER_LAMINATED]) {
          label += ` (${labelSetting[Settings.COVER_LAMINATED][settings[Settings.COVER_LAMINATED]]})`;
        }
      }
      if (e == Settings.FINISHING && (settings[Settings.FINISHING] == Finishing.BINDING || settings[Settings.FINISHING] == Finishing.BINDINGX)) {
        // backward compatible: when grouped setting didn't exist and default was grouped
        const grouped = settings[Settings.GROUPED] == true || settings[Settings.GROUPED] === undefined;
        const _labels = [];
        _labels.push(labelSetting[Settings.GROUPED][grouped.toString()]);
        if (settings[Settings.RING_COLOR]) {
          _labels.push(`${settings[Settings.RING_COLOR]?.name}`);
          // _labels.push(`A: ${settings[Settings.RING_COLOR]?.name}`);
        }
        if (settings[Settings.HARD_COVER_FRONT] && !settings[Settings.COVER_LAMINATED]) {
          _labels.push(`${settings[Settings.HARD_COVER_FRONT].name}`);
          // _labels.push(`T. Delantera: ${settings[Settings.HARD_COVER_FRONT].name}`);
        }
        if (settings[Settings.HARD_COVER_BACK]) {
          _labels.push(`${settings[Settings.HARD_COVER_BACK].name}`);
          // _labels.push(`T. Trasera: ${settings[Settings.HARD_COVER_BACK].name}`);
        }
        label += ` (${_labels.join(', ')})`;
      }
      labels.push(label);
    });
    return labels;
  }

  getNewPrintLabels(settings, files) {
    let labels = [];
    let keys = [ // force order to be shown
      Settings.COLOR, Settings.PAGE_SIZE, Settings.PAPER_WEIGHT, Settings.TWO_SIDED,
      Settings.PAGE_ORIENTATION, Settings.PAGE_TURN, Settings.PAGES_PER_SHEET, Settings.FINISHING,
    ];
    const labelSetting = {
      [Settings.PAGE_SIZE]: {
        [PageSize.A3]: {
          label: "A3",
          description: 'Formato A3'
        },
        [PageSize.A4]: {
          label: "A4",
          description: 'Formato A4'
        },
        [PageSize.A5]: {
          label: "A5",
          description: 'Formato A5'
        },
      },
      [Settings.PAPER_WEIGHT]: {
        [PaperWeight.W80]: {
          label: "80gr",
          description: '80 gramos'
        },
        [PaperWeight.W90]: {
          label: "90gr",
          description: '90 gramos'
        },
        [PaperWeight.W100]: {
          label: "100gr",
          description: '100 gramos'
        },
        [PaperWeight.W120]: {
          label: "120gr",
          description: '120 gramos'
        },
        [PaperWeight.W160]: {
          label: "160gr",
          description: '160 gramos'
        },
        [PaperWeight.W200]: {
          label: "200gr",
          description: '200 gramos'
        },
        [PaperWeight.W300]: {
          label: "300gr",
          description: '300 gramos'
        },
      },
      [Settings.COLOR]: {
        [PrintColor.BLACKNWHITE]: {
          label: "B/N",
          description: 'Blanco y negro',
          type: 'image',
          image: 'assets/icon/black-white-indicator.svg'
        },
        [PrintColor.COLOR]: {
          label: "Color",
          description: 'Color',
          type: 'image',
          image: 'assets/icon/color-indicator.svg'
        },
      },
      [Settings.PAGES_PER_SHEET]: {
        [PagesPerSheet.ONE]: {
          label: "1PagCara",
          description: '1 página por cara'
        },
        [PagesPerSheet.TWO]: {
          label: "2PagCara",
          description: '2 página por cara'
        },
        [PagesPerSheet.FOUR]: {
          label: "4PagCara",
          description: '4 página por cara'
        },
      },
      [Settings.TWO_SIDED]: {
        true: {
          label: "2Cara",
          description: 'A doble cara'
        },
        false: {
          label: "1Cara",
          description: 'A una cara'
        },
      },
      [Settings.PAGE_ORIENTATION]: {
        [PageOrientation.PORTRAIT]: {
          label: "Vert",
          description: 'Vertical'
        },
        [PageOrientation.LANDSCAPE]: {
          label: "Hori",
          description: 'Horizontal'
        },
      },
      [Settings.PAGE_TURN]: {
        [PageTurn.LARGESIDE]: {
          label: "B. Largo",
          description: 'Paso de página por el borde largo'
        },
        [PageTurn.SHORTSIDE]: {
          label: "B. Corto",
          description: 'Paso de página por el borde corto'
        },
      },
      [Settings.FINISHING]: {
        [Finishing.BINDING]: {
          label: "Encuadernación",
          type: 'finishing'
        },
        [Finishing.BINDINGX]: {
          label: "Encuadernación lado corto",
          type: 'finishing'
        }, // backward compatibility
        [Finishing.STAPLED]: {
          label: "Grapado",
          type: 'finishing'
        },
        [Finishing.LAMINATED]: {
          label: "Plastificado",
          type: 'finishing'
        },
        [Finishing.PERFORATED4]: {
          label: "Taladrado 4 agujeros",
          type: 'finishing'
        },
        [Finishing.PERFORATED2]: {
          label: "Taladrado 2 agujeros",
          type: 'finishing'
        },
        [Finishing.NONE]: {
          label: "Sin Acabado",
          type: 'finishing'
        },
      },
    };
    for (const e of keys) {
      if (settings[e] === undefined) {
        break; // backward compatible: setting may not exist in old orders
      }
      let label;
      label = labelSetting[e][settings[e]];
      if (e === Settings.COLOR) {
        if (files.some(file => !file.docColor)) {
          label = labelSetting[e][PrintColor.BLACKNWHITE];
          labels.push(label);
        }
        if (files.some(file => file.docColor) || files.some(file => file.coverColor)) {
          label = labelSetting[e][PrintColor.COLOR];
          labels.push(label);
        }
        continue;
      }
      if (e === Settings.FINISHING) {
        if (settings[Settings.FINISHING] === Finishing.BINDING) {
          label = labelSetting[e][Finishing.BINDING];
          if (settings[Settings.GROUPED]) {
            label.label += ' agrupada';
          } else {
            label.label += ' individual';
          }
          labels.push(label);
          continue;
        }
      }
      labels.push(label);
    }
    return labels;
  }

  /**
   *
   * @param printProduct
   * @param files
   * Recibe un printGroup y files para modificarlos directamente.
   *
   */
  setPrintProduct(printProduct: ShoppingCart.OrderItemUi, files: Array<any>) {
    this.refreshPrintGroupPrice(printProduct.product as Product, files)
    printProduct.price.amt = printProduct.qty * (printProduct.product.unitPrice.amt + printProduct.product.extra.price);

    //Add binding uses
    this.setPrintProductBindingUses(printProduct);

  }

  setPrintProductBindingUses(printProduct) {
    const combineObjects = (targetArray, sourceArray, keyProps) => {
      sourceArray.forEach(obj => {
        const matchingObj = targetArray.find(targetObj => {
          return keyProps.every(key => targetObj[key] === obj[key]);
        });

        if (matchingObj) {
          matchingObj.uses += obj.uses;
        } else {
          targetArray.push({ ...obj });
        }
      });
    };

    const printingGroup = printProduct.product.printingGroup;
    let covers = [];
    let rings = [];
    if (printProduct.product.type === ProductType.PRINT && printingGroup.printSettings[Settings.FINISHING] === Finishing.BINDING) {
      printingGroup.binding.rings = [];
      printingGroup.binding.covers = [];
      if (printingGroup.printSettings.grouped) {
        const diameter = printingGroup.binding.ringDiameter;
        const pageSize = printingGroup.printSettings[Settings.PAGE_SIZE];
        rings = [{
          color: printingGroup.printSettings[Settings.RING_COLOR].id,
          diameter,
          name: printingGroup.printSettings[Settings.RING_COLOR].name,
          uses: printingGroup.binding.count * printProduct.qty
        }];
        if (printingGroup.printSettings[Settings.HARD_COVER_FRONT].id === printingGroup.printSettings[Settings.HARD_COVER_BACK].id) {
          covers = [{
            color: printingGroup.printSettings[Settings.HARD_COVER_BACK].id,
            name: printingGroup.printSettings[Settings.HARD_COVER_BACK].name,
            pageSize,
            uses: printingGroup.binding.count * 2 * printProduct.qty
          }];
        } else {
          covers = [{
            color: printingGroup.printSettings[Settings.HARD_COVER_BACK].id,
            name: printingGroup.printSettings[Settings.HARD_COVER_BACK].name,
            pageSize,
            uses: printingGroup.binding.count * printProduct.qty
          }, {
            color: printingGroup.printSettings[Settings.HARD_COVER_FRONT].id,
            name: printingGroup.printSettings[Settings.HARD_COVER_FRONT].name,
            pageSize,
            uses: printingGroup.binding.count * printProduct.qty
          }];
        }
        combineObjects(printingGroup.binding.rings, rings, ['color', 'diameter']);
        combineObjects(printingGroup.binding.covers, covers, ['color', 'pageSize']);
      } else {

        printingGroup.files.forEach(file => {
          rings = [];
          covers = [];
          if (file[Settings.RING_COLOR]) {

            const diameter = file?.binding?.ringDiameter;
            const pageSize = printingGroup.printSettings[Settings.PAGE_SIZE];
            if (rings.some(r => r.name === file[Settings.RING_COLOR].name && r.id === file[Settings.RING_COLOR].id)) {
              const index = rings.indexOf(rings.find(r => r.name === file[Settings.RING_COLOR].name && r.id === file[Settings.RING_COLOR].id));
              rings[index].uses++;
            } else {
              rings.push({
                color: file[Settings.RING_COLOR].id,
                diameter,
                name: file[Settings.RING_COLOR].name,
                uses: file.binding.count * printProduct.qty
              })
            }

            if (covers.some(c => c.name === file[Settings.HARD_COVER_BACK].name && c.id === file[Settings.HARD_COVER_BACK].id && c.id === file[Settings.HARD_COVER_FRONT].id && c.pageSize === pageSize)) {
              const index = covers.indexOf(covers.find(c => c.name === file[Settings.HARD_COVER_BACK].name && c.id === file[Settings.HARD_COVER_BACK].id && c.pageSize === pageSize));
              covers[index].uses++;
            } else if (covers.some(c => c.name === file[Settings.HARD_COVER_FRONT].name && c.id === file[Settings.HARD_COVER_FRONT].id && c.id === file[Settings.HARD_COVER_FRONT].id && c.pageSize === pageSize)) {
              const index = covers.indexOf(covers.find(c => c.name === file[Settings.HARD_COVER_FRONT].name && c.id === file[Settings.HARD_COVER_FRONT].id && c.pageSize === pageSize));
              covers[index].uses++;
            } else {
              if (file[Settings.HARD_COVER_FRONT].id === file[Settings.HARD_COVER_BACK].id) {
                covers = [{
                  color: file[Settings.HARD_COVER_BACK].id,
                  name: file[Settings.HARD_COVER_BACK].name,
                  pageSize,
                  uses: file.binding.count * 2 * printProduct.qty
                }];
              } else {
                covers = [{
                  color: file[Settings.HARD_COVER_BACK].id,
                  name: file[Settings.HARD_COVER_BACK].name,
                  pageSize,
                  uses: file.binding.count * printProduct.qty
                }, {
                  color: file[Settings.HARD_COVER_FRONT].id,
                  name: file[Settings.HARD_COVER_FRONT].name,
                  pageSize,
                  uses: file.binding.count * printProduct.qty
                }];
              }
            }
            file.binding.covers = covers;
            file.binding.rings = rings;
            combineObjects(printingGroup.binding.rings, file.binding.rings, ['color', 'diameter']);
            combineObjects(printingGroup.binding.covers, file.binding.covers, ['color', 'pageSize']);
          }
        });
      }
    }
  }

  /**
   * Modifica el producto tipo print es el principal para home y document-edit
   * CUIDADO
   * @param printProduct Es el producto como tal dentro del printgroup
   * @param viewFiles  los archivos subidos
   */
  refreshPrintGroupPrice(printProduct: Product, viewFiles) {

    let subTotal = 0;
    const settings = cloneDeep(printProduct.printingGroup.printSettings);
    let totalGroupSides = 0;
    let fullSheets = 0;
    let totalBlankSheets = 0;

    //Recalculamos los precios por cada archivo de este grupo de impresión.
    //Actualizamos el precio por cada archivo y acumulamos el total
    let _finishing = {
      description: '',
      units: 0,
      price: 0,
    }
    let ringsWeight = 0;
    let ringsCount = 0;
    let ringsSplit = false;
    let extraWeight = 0;

    let isColor = settings.color == PrintColor.COLOR

    if (!isColor) {
      //Se pretende ver si realmente no es todo una impresion a color.
      //Casos que se han marcado individualemnte que es a color a pesar de que afurea se marcara byn
      isColor = printProduct.printingGroup.files.reduce((isColor: boolean, file) => {
        return isColor && (file.docColor || (file.coverColor && file.pages === 1));
      }, true)
    }

    const isBinding = settings.finishing == Finishing.BINDING || settings.finishing == Finishing.BINDINGX;


    printProduct.printingGroup.files.forEach((file: _PrintFile, i) => {
      if (file) {
        let coverColorAdjust = 0
        // if (file.pages > 1 && (!isColor && !file.docColor && file.coverColor) || ((isColor || file.docColor) && file.coverLaminated)) {
        //   coverColorAdjust = 1
        // }
        const coverType = file.coverType;
        const blankSheets = (file.blankSheetsBefore ?? 0) + (file.blankSheetsAfter ?? 0);
        const totalSides = this.getTotalSides(file.pages, settings, coverType); // this is actually sides, not sheets
        const _fileSheets = this.getFileSheets(totalSides, settings, coverType) + coverColorAdjust + blankSheets;
        file.printSheets = _fileSheets;

        //Si se debe hacer el acabado por archivo
        if (!settings['grouped'] || settings[Settings.FINISHING] !== Finishing.BINDING) {
          const _fileFinishing = this.getFinishing(_fileSheets, settings, printProduct.printingGroup, file);
          _finishing.description = _fileFinishing.description;
          _finishing.units += _fileFinishing.units;
          _finishing.price += _fileFinishing.price;
          if (isBinding) {
            // console.log("@p ring", {_fileSheets, pageSize: settings.pageSize, pageTurn: settings.pageTurn})
            const BindingInfo = this.bindingHelper.findBindingRing(_fileSheets, settings.pageSize, settings.paperWeight, settings.pageTurn,
              EXTRAPRICES, file);
            file.binding = {
              count: BindingInfo.qty,
              ringSplit: BindingInfo.split,
              ringDiameter: BindingInfo.diameter,
              weight: BindingInfo.weight
            }
            // console.log("fileBindind", file.binding  )
            ringsWeight += BindingInfo.weight;
            ringsCount += BindingInfo.qty;
            ringsSplit = ringsSplit || BindingInfo.split;
          }
        } else {
          file.binding && delete file.binding
        }

        fullSheets += _fileSheets;
        totalGroupSides += totalSides + coverColorAdjust;
        //Seteamos col coverColor del file para poder determinar correctamente el precio
        const _settings = cloneDeep(settings);
        _settings.coverColor = file.coverColor;
        _settings.docColor = file.docColor || (file.coverColor && file.pages === 1);
        const total = this.getFilePrice(totalSides, _settings, coverType, blankSheets, file,);
        //Este total se utiliza para mostrarlo en la tarjeta y evitar calcularlo en file
        if (viewFiles)
          viewFiles[i].total = total;
        if (blankSheets) {
          totalBlankSheets += blankSheets;
        }

        subTotal += total;
      }
    });  // end for each file

    printProduct.printingGroup.printSheets = fullSheets;
    printProduct.printingGroup.printSides = totalGroupSides;
    printProduct.unitPrice.amt = !printProduct?.unitPrice?.amtWithTax ? subTotal : round2(subTotal / 1.21);

    //Obtenemos los valores de encuadernacion si está agrupado
    if (settings['grouped'] && settings[Settings.FINISHING] === Finishing.BINDING) {
      _finishing = this.getFinishing(fullSheets, settings, printProduct.printingGroup);
    }

    //Seteamos los valores de encuadernacion
    if (isBinding) {
      if (settings['grouped']) {
        const BindingInfo = this.bindingHelper.findBindingRing(fullSheets, settings.pageSize, settings.paperWeight, settings.pageTurn,
          EXTRAPRICES, printProduct.printingGroup.files[0]);
        printProduct.printingGroup.binding = {
          count: BindingInfo.qty,
          ringSplit: BindingInfo.split,
          ringDiameter: BindingInfo.diameter,
          weight: BindingInfo.weight
        }
        ringsWeight = BindingInfo.weight; // MN: faltaba contar el peso de las encuadernaciones agrupadas
      } else {
        printProduct.printingGroup.binding = {
          count: ringsCount,
          ringSplit: ringsSplit,
          ringDiameter: null,
          weight: ringsWeight
        }
      }
    } else {
      printProduct.printingGroup.binding = null;

      extraWeight = this.getFinishingWeight(settings.finishing, printProduct)
    }

    const finishing = _finishing;
    printProduct.extra = finishing;

    printProduct.copyPrice = this.getPriceOption(settings);

    if (totalBlankSheets) {
      printProduct.extra.blankSheetsUnits = totalBlankSheets;
      printProduct.extra.blankSheetsPrice = totalBlankSheets * (this.getPriceOption(settings) * (this.blankSheetPercentageCalc / 100));
    }

    const sheetsWeight = this.getPrintingGroupSheetsWeight(printProduct.printingGroup);
    printProduct.printingGroup.weight = sheetsWeight + ringsWeight + extraWeight;
  }


  public getPriceOption(settings) {
    const options = ['color', 'pageSize', 'paperWeight', 'twoSided', 'pagesPerSheet'].map(_setting => {
      return settings[_setting]
    }).sort().join(',');

    const priceOption = this.tablePrices.find(price => {
      return options === price.options.sort().join(',')
    })

    let price = 0; //Podriamos colocar un precio por defecto porsia;
    if (priceOption) {
      price = priceOption.price;
    }
    return price;
  }

  private mergeVariants(papers: any, customizablePapers: any): any[] {
    return [...papers.variants, ...customizablePapers.variants].map(variant => {
      return variant;
    });
  }

  initializeCombinedPapers(): Promise<void> {
    return new Promise(resolve => {
      if (this.combinedPapers) {
        resolve();
      }
      combineLatest(this.papers$, this.customizablePapers$).pipe(
        map(([papers, customizablePapers]) => this.mergeVariants(papers, customizablePapers)),
        take(1)
      ).subscribe(combinedPapers => {
        this.combinedPapers = combinedPapers;
        resolve();
      });
    });
  }

  checkDocColorsExceptions(printProduct) {
    const printSettings = printProduct.product.printingGroup.printSettings;
    const files = printProduct.product.printingGroup.files;
    const colorCountChecked = files.filter(
      (file) => file.docColor
    ).length;
    printProduct.product.printingGroup.printSettings.docColor = printSettings.color === 'color' ? colorCountChecked == files.length : !!colorCountChecked;
    const coverColorCountChecked =
      printProduct.product.printingGroup.files.filter(
        (file) => file.coverColor
      ).length;
    printProduct.product.printingGroup.printSettings.coverColor =
      !!coverColorCountChecked;
  }

  checkIndividualBindingSettings(printProduct, event, settings) {
    const _printSettings = cloneDeep(printProduct.product.printingGroup.printSettings);
    if (_printSettings.finishing === 'binding') {
      printProduct.product.printingGroup.files.forEach((file, index) => {
        if (!file.ringColor) {
          this.addBindingDataToFile(printProduct, index, _printSettings)
        } else if (event && ['ringColor', 'hardCoverFront', 'hardCoverBack'].includes(event.setting) && !_printSettings.grouped) {
          _printSettings[event.setting] = settings[event.setting].options[event.option - 1].value;
          this.addBindingDataToFile(printProduct, index, _printSettings, event.setting)
        }
      })
    }
  }

  private addBindingDataToFile(printProduct, index, printSettings, setting = null) {
    if (setting) {
      printProduct.product.printingGroup.files[index][setting] = printSettings[setting];
    } else {
      printProduct.product.printingGroup.files[index].ringColor = printSettings.ringColor;
      printProduct.product.printingGroup.files[index].hardCoverFront = printSettings.hardCoverFront;
      printProduct.product.printingGroup.files[index].hardCoverBack = printSettings.hardCoverBack;
    }

  }
}

