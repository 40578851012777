import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from "../shared/app.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { firstValueFrom } from "rxjs";
import { OrderPaymentStatus, OrderProcessStatus, TrackingData } from "../models/models";
import { IonContent } from "@ionic/angular";

@Component({
  selector: 'app-get-client-shipping-info',
  templateUrl: './get-client-shipping-info.component.html',
  styleUrls: ['./get-client-shipping-info.component.scss'],
})
export class GetClientShippingInfoComponent implements OnInit {
  @ViewChild('content', { static: false }) content: IonContent;
  order = null
  shippingInfoForm: FormGroup;
  submitErrorMessage: string = '';
  showTrackingData = false;
  trackingData: TrackingData

  constructor(public appService: AppService, private fb: FormBuilder, private db: AngularFirestore) {
    this.shippingInfoForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      orderNumber: ['', [Validators.required, Validators.pattern('^[0-9]+$')]]
    });
  }

  ngOnInit(): void {
    this.shippingInfoForm.valueChanges.subscribe(() => {
      this.submitErrorMessage = '';
    });
  }

  getErrorMessage(controlName: string): string {
    const control = this.shippingInfoForm.get(controlName);

    if (control?.hasError('required')) {
      return 'Este campo es obligatorio.';
    } else if (control?.hasError('email')) {
      return 'Introduce una dirección de email válida.';
    } else if (control?.hasError('pattern')) {
      return 'Se han detectado caracteres inválidos.';
    }

    return '';
  }

  filterNonNumeric(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
  }

  async onSubmit() {
    if (this.shippingInfoForm.valid) {
      const email = this.shippingInfoForm.value.email;
      const orderNumber = Number(this.shippingInfoForm.value.orderNumber);
      const orderSnap = await firstValueFrom(this.db.collection('orders', ref =>
        ref.where('email', '==', email)
          .where('number', '==', orderNumber)
          .limit(1)
      ).get());

      if (orderSnap.empty) {
        this.showTrackingData = false
        this.submitErrorMessage = 'No se ha encontrado ningún pedido con esos datos'
        return
      } else {
        const shippingLabelId = orderSnap.docs[0].get('shippingLabelId')
        const shippingEstimatedDelivery = orderSnap.docs[0].get('shippingEstimatedDelivery')
        const shippingCourierService = orderSnap.docs[0].get('shippingCourierService')
        const processStatus = orderSnap.docs[0].get('processStatus')
        const paymentStatus = orderSnap.docs[0].get('paymentStatus')
        const shippingDelayReported = orderSnap.docs[0].get('shippingDelayReported')
        const shippingAddress = {};
        shippingAddress['pickupPointCode'] = orderSnap.docs[0].get('shippingAddress.pickupPointCode')
        if (paymentStatus !== OrderPaymentStatus.PAID || ![OrderProcessStatus.PENDING, OrderProcessStatus.PROCESSING,
          OrderProcessStatus.COMPLETE, OrderProcessStatus.SHIPPED].includes(processStatus)) {
          this.showTrackingData = false
          this.submitErrorMessage = 'No existe un envío asociado a este pedido'
          return
        }

        if (shippingLabelId) {
          const labelSnap = await firstValueFrom(this.db.collection('shippingLabels').doc(shippingLabelId).get())
          if (!labelSnap.exists) {
            this.showTrackingData = false
            this.submitErrorMessage = 'Ha ocurrido un error al consultar el envío.'
            return
          }

          const {
            expectedDeliveryDate = null,
            internalStatus = null,
            onTime = null,
            pickupDate = null,
            url = null
          } = labelSnap.get('tracking') || {};

          this.trackingData = {
            internalStatus: internalStatus || null,
            minDeliveryDate: shippingEstimatedDelivery?.minDeliveryDate ? new Date(shippingEstimatedDelivery?.minDeliveryDate.seconds * 1000)
              : null,
            maxDeliveryDate: shippingEstimatedDelivery?.maxDeliveryDate ? new Date(shippingEstimatedDelivery?.maxDeliveryDate.seconds * 1000)
              : null,
            url
          }

        } else {
          this.trackingData = {
            internalStatus: null,
            minDeliveryDate: shippingEstimatedDelivery?.minDeliveryDate ? new Date(shippingEstimatedDelivery?.minDeliveryDate.seconds * 1000)
              : null,
            maxDeliveryDate: shippingEstimatedDelivery?.maxDeliveryDate ? new Date(shippingEstimatedDelivery?.maxDeliveryDate.seconds * 1000)
              : null,
            url: null
          }
        }
        this.order = {
          shippingCourierService,
          processStatus,
          paymentStatus,
          shippingAddress,
          number: orderNumber,
          shippingDelayReported
        }
        this.showTrackingData = true
        return
      }
    }

  }

  scrollToBottom() {
    if (this.content)
      this.content.scrollToBottom(500);
  }


}
