import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { ConfirmModalCartComponent } from 'src/app/components/confirm-modal-cart/confirm-modal-cart.component';
import { PrintProductStateService } from 'src/app/home/print-product-state.service';
import { ShoppingCart } from 'src/app/models/cart.model';
import { Product, ProductType } from 'src/app/models/product.model';
import { FileUploadService } from 'src/app/shared/a2-files/services/file-upload.service';
import { CartService } from 'src/app/shared/cart.service';
import { ProductService } from 'src/app/shared/product.service';
import { _PrintFile, Finishing, PrintFileStatus } from 'src/app/models/models';
import { takeWhile, tap } from 'rxjs';
import { UploadState } from 'src/app/shared/a2-files/services/file-upload.service';
import { FileService } from 'src/app/shared/file.service';

@Component({
  selector: 'app-item-print-product',
  templateUrl: './item-print-product.component.html',
  styleUrls: ['./item-print-product.component.scss'],
  providers: [{provide: FileUploadService}]
})
export class ItemPrintProductComponent implements OnInit {

  @Input() item: ShoppingCart.OrderItem;
  @Input() i;
  @Input() disableActions;
  @Input() showQty;

  viewDetails: boolean = false;
  productWithoutStock: boolean;
  isGroup: boolean;
  _printFileStatus = PrintFileStatus;
  finishing = Finishing;

  withoutStock: {[fileId: string]: {ringColor: boolean, hardCoverFront: boolean, hardCoverBack: boolean}} = {};
  bindingStockExceeded;

  coverSettings;

  constructor(
    public cartService: CartService,
    private router: Router,
    private menuCtrl: MenuController,
    private productService: ProductService,
    private modalController: ModalController,
    public printProductStateService: PrintProductStateService,
    private fileUploadService: FileUploadService,
    private fileService: FileService
  ) { }

  ngOnInit() {
    
    /*this.printProductStateService.settingsChanged$.subscribe(() => {
      setTimeout(() => {
        this.checkStockStatus();
      }, 300);
    });*/

    /*this.fileService
        .getTemporaryFile(file.id, "uploads")
        .pipe(takeWhile(() => file && file.status !== this._printFileStatus.FINISHED))
        .subscribe((file: _PrintFile) => {
          if (file.status === this._printFileStatus.FINISHED) {
            this.isSoldOut(file, 'ringColor');
            this.isSoldOut(file, 'hardCoverFront');
            this.isSoldOut(file, 'hardCoverBack');
            this.updateProductWithoutStock();
          }
        });*/
    this.coverSettings = this.fileService.getCoverSettings();
  }


  async onDelete(item) {
    const modal = await this.modalController.create({
      component: ConfirmModalCartComponent,
      cssClass: "auto-height delete-folder-modal",
      backdropDismiss: false,
      componentProps: {
        folderName: this.getFolderName(this.item, this.i),
        item: this.item,
        deleteModal: true
      },
    });
    modal.present();
  }

  onQtyChange(event) {
    this.item.qty = event.qty
    this.productService.setPrintProduct(this.item, this.item.product.printingGroup.files);
    this.cartService.refreshCartPrice();
    this.cartService.setCartBindingUses();
  }

  /*onQtyChange(event) {
    const product = this.item.product as Product;
    const qty = event.qty - this.item.qty
    this.cartService.updateProductCart(this.item, qty)
  }*/

  formatProperties(props: object) {
    const properties = [];
    this.item.product.optionsValue.forEach(opt => {
      const prop = Object.entries(props).find(([k, v]) => v === opt);
      if (prop) {
        properties.push({
          key: prop[0],
          value: prop[1],
        });
      }
    });
    return properties;
  }

  toggleViewDetails() {
    this.viewDetails = !this.viewDetails;
  }

  editPrintProduct(item) {
    const navigationExtras: NavigationExtras = { state: { item } };
    this.router.navigate(["/"], navigationExtras);

    // Close side menu after navigating to home page
    this.menuCtrl.close('cart-preview');
  }

  getFolderName(item, i) {
    return item.product.folderName ?? `Grupo #${i + 1}`
  }

  getCoverTypeSetting(file) {
    let setting = this.coverSettings.find(setting => setting.value.id === file.coverType.id);
    return setting;
  }


  // Comprueba el stock de los items de encuadernado teniendo en cuenta tambien
  // el estado de subida de un archivo
  /*checkStockStatus() {
    // this.cartService.refreshPrintProductsBindingUses();
    this.item.product.printingGroup.files.forEach((fileItem) => {
      this.fileService
        .getTemporaryFile(fileItem.id, "uploads")
        //.pipe(takeWhile(() => file && file.status !== this._printFileStatus.FINISHED))
        .subscribe((file: _PrintFile) => {
          if (file.status === this._printFileStatus.FINISHED) {
            this.isSoldOut(fileItem, 'ringColor');
            this.isSoldOut(fileItem, 'hardCoverFront');
            this.isSoldOut(fileItem, 'hardCoverBack');
            this.updateProductWithoutStock();
          }
        });
    });
  }*/

    // Esta función se ha usado para detectar concretamente qué elemento del encuadernado está sin stock
    // Hay que revisarla
/*
  isSoldOut(file, option) {
    const _printSettings = this.item.product.printingGroup.printSettings;
    const isHardCover = ['hardCoverFront', 'hardCoverBack'].includes(option);
    const isRingColor = option === 'ringColor';
    const stockArray = isHardCover ? this.cartService.bindingStock.covers : this.cartService.bindingStock?.rings;


    const cartBindingUses = this.cartService.cartBindingStockUses;
    let itemStock;
    let itemCartUses;
    
    let soldOut = false;
    let ring;

    if (!this.withoutStock[file.id]) {
      this.withoutStock[file.id] = {
        ringColor: false,
        hardCoverFront: false,
        hardCoverBack: false
      };
    }

    if (stockArray.length && (this.item.product.printingGroup.binding || file.bingind)) {
      if (_printSettings.grouped) {
        if (isRingColor) {
          ring = _printSettings.ringColor;
          itemStock = stockArray.find(el => el.color === ring.id && el['diameter'] == this.item.product.printingGroup.binding?.ringDiameter);
          itemCartUses = cartBindingUses.rings.find(el => el.color === ring.id && el.diameter == this.item.product.printingGroup.binding?.ringDiameter)?.uses ?? 0;
        }
        if (isHardCover) {
          itemStock = stockArray.find(el => el.color === _printSettings[option].id && el['pageSize'] == this.item.product.printingGroup.printSettings.pageSize);
          itemCartUses = cartBindingUses.covers.find(el => el.color === _printSettings[option].id && el.pageSize == this.item.product.printingGroup.printSettings.pageSize)?.uses ?? 0;
        }
      } else {
        if (file) {
          if (isRingColor) {
            ring = file.ringColor;
            itemStock = stockArray.find(el => el.color === ring.id && el['diameter'] == file.binding?.ringDiameter);
            itemCartUses = cartBindingUses.rings.find(el => el.color === ring.id && el.diameter == file.binding?.ringDiameter)?.uses ?? 0;
          }
          if (isHardCover) {
            itemStock = stockArray.find(el => el.color === file[option].id && el['pageSize'] == this.item.product.printingGroup.printSettings.pageSize);
            itemCartUses = cartBindingUses.covers.find(el => el.color === file[option].id && el.pageSize == this.item.product.printingGroup.printSettings.pageSize)?.uses ?? 0;
          }
        }
      }
      
      soldOut = itemStock ? itemStock.stock - itemCartUses < 0 : true;
      
      this.withoutStock[file.id][option] = soldOut;
    }

    
    //return soldOut; option.value.soldOut;
  }*/

  updateProductWithoutStock() {
    this.productWithoutStock = Object.values(this.withoutStock).some(
      (file) => file.ringColor || file.hardCoverFront || file.hardCoverBack
    );
    this.viewDetails = this.productWithoutStock;
    
  }

  bindingOptionValue(file, option, param) {
    let ret;
    let isGroup = this.item.product.printingGroup.printSettings.grouped;
    switch (option) {
      case 'ringColor':
        if (param === 'id')
          ret = (isGroup ? this.item.product.printingGroup.printSettings.ringColor : file.ringColor)?.id;
        if (param === 'name')
          ret = (isGroup ? this.item.product.printingGroup.printSettings.ringColor : file.ringColor)?.name;
        break;
      case 'hardCoverFront':
        if (param === 'id')
          ret = (isGroup ? this.item.product.printingGroup.printSettings.hardCoverFront : file.hardCoverFront)?.id;
        if (param === 'name')
          ret = (isGroup ? this.item.product.printingGroup.printSettings.hardCoverFront : file.hardCoverFront)?.name;
        break;
      case 'hardCoverBack':
        if (param === 'id')
          ret = (isGroup ? this.item.product.printingGroup.printSettings.hardCoverBack : file.hardCoverBack)?.id;
        if (param === 'name')
          ret = (isGroup ? this.item.product.printingGroup.printSettings.hardCoverBack : file.hardCoverBack)?.name;
        break;
    }

    return ret;
  }


}
