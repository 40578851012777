import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverController } from "@ionic/angular";
import { OutputMessage } from 'src/app/models/common/output.model';
import { Order, OrderPaymentStatus, OrderProcessStatus, OrderTypeStatus } from 'src/app/models/models';

@Component({
  selector: 'app-order-actions-menu',
  templateUrl: './order-actions-menu.component.html',
  styleUrls: ['./order-actions-menu.component.scss'],
})
export class OrderActionsMenuComponent {

  @Input() isDraft: boolean;
  @Input() order: Order;
  @Output() menuAction = new EventEmitter<OutputMessage>();

  orderTypeStatus: typeof OrderTypeStatus = OrderTypeStatus;
  paymentStatus: typeof OrderPaymentStatus = OrderPaymentStatus;

  constructor(private popoverCtrl: PopoverController) {
  }

  public emitAction(type: string, value?: any): void {
    this.popoverCtrl.dismiss({type: type, value: value});
  }
  
  get isPaid() {
    return this.order.paymentStatus == OrderPaymentStatus.PAID
  }

  get isRefunded() {
    return this.order.paymentStatus == OrderPaymentStatus.REFUNDED
  }

  get isPending() {
    return this.order.paymentStatus == OrderPaymentStatus.PENDING
  }

  get isProcessPending() {
    return this.order.processStatus == OrderProcessStatus.PENDING
  }

  get isProcessCompleted() {
    return this.order.processStatus == OrderProcessStatus.COMPLETE
  }

  get isProcessShipped() {
    return this.order.processStatus == OrderProcessStatus.SHIPPED
  }

  get isPauseButtonDisplayed() {
    return [
      OrderProcessStatus.PENDING, // now: pending only
      // OrderProcessStatus.PROCESSING,
      // OrderProcessStatus.COMPLETE,
      // OrderProcessStatus.SHIPPED
    ].includes(this.order.processStatus) && !this.order.cart.items.some(it => it.product.presale)
  }

  get alloworderDataModification() {
    return [OrderProcessStatus.PENDING, OrderProcessStatus.PROCESSING].includes(this.order.processStatus);
  }

}
